import React from 'react'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import MyPage from '../components/my_page'

import MyImagesSingle from '../components/my_images_single'

const SpecialistaiPage = () => {
  const pageTitle = 'Specialistai'
  const pageCards = [
    {
      title: <b>Agnė Stasiulytė-Sokolovienė (bendraįkūrėja) </b>,
      body: (
        <Row>
          <Col xl={5} lg={5} md={5}>
            <MyImagesSingle
              imgPublicId="musupedutes/kiti/agne"
              wrapLeft
              imgContain
            />
          </Col>
          <Col xl={7} lg={7} md={7} sm={12} xs={12}>
            <ul>
              <li>
                Lietuvos edukologijos universitetas - psichologija (bakalauras)
              </li>
              <li>Vilniaus universitetas - psichologija (magistras)</li>
              <li>
                Vilniaus universitetas - ikimokyklinis ir priešmokyklinis
                ugdymas
              </li>
            </ul>
            <p>
              <i>
                „Darželis gimė iš begalinės meilės vaikučiams, kuri formavosi
                daug metų. Manau, kad auginti žmogų yra vienas iš svarbiausių
                darbų planetoje ir esu dėkinga likimui, kad galiu prisiliesti
                prie šio stebuklo: dalyvauti ŽMOGAUS augimo misterijoje. Tai man
                nėra darbas, tai yra misija, kurią atlieku su didžiausiu
                džiaugsmu ir meile.“
              </i>
            </p>
          </Col>
        </Row>
      )
    },
    {
      title: <b>Evelina Valiukaitė (bendraįkūrėja)</b>,
      body: (
        <Row>
          <Col xl={5} lg={5} md={5}>
            <MyImagesSingle
              imgPublicId="musupedutes/kiti/evelina"
              wrapLeft
              imgContain
            />
          </Col>
          <Col xl={7} lg={7} md={7} sm={12} xs={12}>
            <ul>
              <li>Vilniaus universitetas – psichologija (bakalauras)</li>
              <li>Vilniaus universitetas – psichologija (magistras)</li>
            </ul>
            <p>
              <i>
                „Vaikai nuo pat gimimo jau yra savitos asmenybės. Prieiti prie
                kiekvieno vaiko, rasti ryšį su juo ir kelią į jo širdelę yra
                didelis iššūkis. Tačiau radus tinkamą raktą laukia pasitikintis
                vaiko žvilgsnis, šypsena ir augimas.“
              </i>
            </p>
          </Col>
        </Row>
      )
    },
    {
      title: <b>Rasa (anglų kalbos mokytoja)</b>,
      body: (
        <span>
          <ul>
            <li>Vilniaus universitetas - filologija (anglų k.)</li>

            <li>Anglų k. mokyklėlės „Džeko pupos" sumanytoja</li>
            <li>Dirba pagal ankstyvojo vaikų anglų k. mokymo programą</li>
          </ul>
        </span>
      )
    },
    {
      title: <b>Nerijus (sporto treneris)</b>,
      body: (
        <span>
          <ul>
            <li>
              Lietuvos Edukologijos universitetas – sporto ir sveikatos
              pedagogika
            </li>

            <li>Vaikų futbolo treneris nuo 2005 m.</li>
          </ul>
        </span>
      )
    },
    {
      title: <b>Dalia (etnografė, muzikos pedagogė)</b>,
      body: (
        <span>
          <ul>
            <li>Muzikos ir muzikologijos bakalauro bei magistro laipsniai</li>
          </ul>
        </span>
      )
    },
    {
      title: <b>Tautvilė (šokių mokytoja)</b>,
      body: (
        <span>
          <ul>
            <li>Specializacija - tautiniai šokiai</li>
            <li>Darželyje veda tautinių šokių būrelį „Giliukai"</li>
          </ul>
        </span>
      )
    }
  ]

  return <MyPage pageType="card" pageTitle={pageTitle} pageCards={pageCards} />
}

export default SpecialistaiPage
